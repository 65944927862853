import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";


const Footer = () => {
    const location = useLocation();
    if (location.pathname === "/") {
        return (
            <div>
                <footer className="footer">
                <div className="footer__el1">
                    <p className="t-15">
                    Pridajte sa k exkluzívnym programom a využite bezplatné pobyty, stravovacie výhody a ešte viac.<br /><br />
                    E-mail:<br />
                    <a href="mailto:info@sectionhotel.com">info@sectionhotel.com</a>
                    </p>
                </div>
                <div className="footer__el2">
                    
                    <Link className="t-15" to="/privacy">Zásady ochrany osobných údajov</Link>
                    <Link className="t-15" to="/terms">Podmienky používania</Link>
                    <Link className="t-15" to="/contact">Kontakt</Link>
                </div>
                </footer>
                <p style={{ textAlign: "center", color: "white", marginTop: "-20px", marginBottom: "0px", paddingBottom: "50px" }}>
                    &copy; 2024 Section Hotel
                </p>
            </div>
          );
      }
    
      if (location.pathname === "/page" || location.pathname === "/page/" || location.pathname === "/page/privacy" || location.pathname === "/page/privacy/"|| location.pathname === "/page/terms" || location.pathname === "/page/terms/"|| location.pathname === "/page/contact" || location.pathname === "/page/contact/") {
        return (
            <div>
                <footer className="footer">
                <div className="footer__el1">
                    <p className="t-15">
                    Pridajte sa k exkluzívnym programom a využite bezplatné pobyty, stravovacie výhody a ešte viac.<br /><br />
                    E-mail:<br />
                    <a href="mailto:info@sectionhotel.com">info@sectionhotel.com</a>
                    </p>
                </div>
                <div className="footer__el2">
                    
                    <Link className="t-15" to="/page/privacy">Zásady ochrany osobných údajov</Link>
                    <Link className="t-15" to="/page/terms">Podmienky používania</Link>
                    <Link className="t-15" to="/page/contact">Kontakt</Link>
                </div>
                </footer>
                <p style={{ textAlign: "center", color: "white", marginTop: "-20px", marginBottom: "0px", paddingBottom: "50px" }}>
                    &copy; 2024 Section Hotel
                </p>
            </div>
          );
      }
  
};

export default Footer;
