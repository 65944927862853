import React from "react";
import Layout from "../components/Layout";

const Home = () => {
  return (
    <Layout>
<>
  <section className="history width">
    <h2 className="history__header">
      Váš sprievodca najlepšími ubytovaniami na Slovensku
    </h2>
    <p className="t-16 col-gr">
      Naším cieľom je pomôcť vám objaviť, porovnať a vybrať si ideálny hotel pre
      vašu ďalšiu cestu, aby každý pobyt bol plný nezabudnuteľných zážitkov.
    </p>
    <ul className="history__item-list">
      <li className="history__item">
        <img
          src="/arrow.ba2ab664913751d89009b18d097f1c0d.svg"
          alt="arrow"
          className="history__arrow"
        />
        <h4 className="history__item-header">Naša vízia</h4>
        <p className="t-16 col-gr">
          V Section Hotel je našou víziou zmeniť spôsob, akým cestovatelia
          objavujú a zažívajú pohostinnosť na Slovensku. Veríme, že každý hosť
          si zaslúži pobyt, ktorý zodpovedá jeho jedinečnému štýlu, preferenciám
          a potrebám. Preto sme odhodlaní ponúkať starostlivo vybraný výber
          hotelov, ktoré stelesňujú to najlepšie z pohodlia a služieb.
        </p>
      </li>
      <li className="history__item">
        <img
          src="/arrow.ba2ab664913751d89009b18d097f1c0d.svg"
          alt="arrow"
          className="history__arrow"
        />
        <h4 className="history__item-header">Náš sľub</h4>
        <p className="t-16 col-gr">
          Sľubujeme, že vaše hľadanie dokonalého hotela bude jednoduché,
          príjemné a prínosné. V spolupráci s najlepšími hotelmi na Slovensku
          prinášame prehľadnú platformu, kde je kvalita zaručená. Naším záväzkom
          je ponúkať iba dôveryhodné a výnimočné ubytovanie, čím vytvárame ľahký
          a spoľahlivý zážitok z rezervácie pre každého hosťa.
        </p>
      </li>
      <li className="history__item">
        <img
          src="/arrow.ba2ab664913751d89009b18d097f1c0d.svg"
          alt="arrow"
          className="history__arrow"
        />
        <h4 className="history__item-header">Čo robíme</h4>
        <p className="t-16 col-gr">
          Section Hotel nie je len webová stránka; je to váš osobný sprievodca,
          ktorý vám pomôže objaviť najlepšie hotely na Slovensku. Predstavujeme
          širokú škálu ubytovacích možností — od luxusných mestských hotelov až
          po útulné vidiecke penzióny — každý z nich je vybraný tak, aby
          vyhovoval rôznym vkusom a cestovateľským cieľom. Nech je váš účel
          akýkoľvek, pomôžeme vám nájsť to pravé miesto.
        </p>
      </li>
      <li className="history__item">
        <img
          src="/arrow.ba2ab664913751d89009b18d097f1c0d.svg"
          alt="arrow"
          className="history__arrow"
        />
        <h4 className="history__item-header">Prečo si vybrať nás?</h4>
        <p className="t-16 col-gr">
          S hlbokým pochopením miestneho hotelového prostredia náš tím nejde len
          o zverejnenie hotelov. Každú nehnuteľnosť starostlivo posudzujeme
          podľa jej štandardov, recenzií a spokojnosti hostí. Naším cieľom je
          pomôcť vám urobiť informované rozhodnutia a zabezpečiť, aby váš pobyt
          bol čo najpríjemnejší a nezabudnuteľný.
        </p>
      </li>
    </ul>
  </section>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n                    .hotel__name{\n                        padding-left: 0px;\n                    }\n                "
    }}
  />
  <section className="hotel width">
    <h2 className="hotel__header">
      Starostlivo vybraný zoznam najlepších hotelov na Slovensku
    </h2>
    <ul className="hotel__item-list">
      <li className="hotel__item">
        <div className="hotel__img-container">
          <img
            src="/hotel-exterior.jpg"
            width={442}
            height={453}
            className="hotel__img"
          />
        </div>
        <div className="hotel__text-container">
          <h4 className="hotel__name">Crowne Plaza Bratislava</h4>
          <p className="t-16">
            Hodžovo námestie 2, Bratislava 816 25, Slovensko
          </p>
          <p className="t-16">
            Hotel sa nachádza priamo v historickom centre mesta s výhľadom na
            Prezidentský palác, len niekoľko minút chôdze od historického centra
            a Bratislavského hradu.
          </p>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g274924-d281813-Reviews-Crowne_Plaza_Bratislava_an_IHG_hotel-Bratislava_Bratislava_Region.html"
            className="btn-hotel"
          >
            Rezervujte teraz
          </a>
        </div>
      </li>
      <li className="hotel__item">
        <div className="hotel__img-container">
          <img
            src="/hotel-zilina.jpg"
            width={442}
            height={453}
            className="hotel__img"
          />
        </div>
        <div className="hotel__text-container">
          <h4 className="hotel__name">Holiday Inn Žilina</h4>
          <p className="t-16">Športová ulica 2, Žilina 010 10, Slovensko</p>
          <p className="t-16">
            Hotel je ľahko dostupný z hlavných diaľničných ťahov. Vyskúšajte
            pohodlie komfortne zariadených izieb a vychutnajte si krásny výhľad
            na panorámu pohoria Malej Fatry obklopujúce hotel.
          </p>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g274946-d640169-Reviews-Holiday_Inn_Zilina_an_IHG_hotel-Zilina_Zilina_Region.html"
            className="btn-hotel"
          >
            Rezervujte teraz
          </a>
        </div>
      </li>
      <li className="hotel__item">
        <div className="hotel__img-container">
          <img
            src="/519841122.jpg"
            width={442}
            height={453}
            className="hotel__img"
          />
        </div>
        <div className="hotel__text-container">
          <h4 className="hotel__name">Vienna House Easy Bratislava </h4>
          <p className="t-16">Galvaniho 28, Bratislava 821 04, Slovensko</p>
          <p className="t-16">
            Hotel Vienna House Easy Bratislava je najbližší hotel k
            Medzinárodnému letisku Bratislava. Nachádza sa vedľa Galvaniho
            Business Centra a AVION Shopping Parku, len 10 minút jazdy od centra
            Bratislavy.
          </p>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g274924-d1455775-Reviews-Vienna_House_Easy_Bratislava-Bratislava_Bratislava_Region.html"
            className="btn-hotel"
          >
            Rezervujte teraz
          </a>
        </div>
      </li>
      <li className="hotel__item">
        <div className="hotel__img-container">
          <img
            src="/ac-hotel-by-marriott.jpg"
            width={442}
            height={453}
            className="hotel__img"
          />
        </div>
        <div className="hotel__text-container">
          <h4 className="hotel__name">AC Hotel Bratislava Old Town </h4>
          <p className="t-16">Vysoká 2A, Bratislava 811 06, Slovensko</p>
          <p className="t-16">
            AC Hotel by Marriott Bratislava Old Town, nachádzajúci sa v
            očarujúcej štvrti Staré Mesto, ponúka elegantné ubytovanie pre
            cestovateľov, ktorí hľadajú pravú podstatu bratislavského pobytu.
          </p>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g274924-d22980246-Reviews-AC_Hotel_Bratislava_Old_Town-Bratislava_Bratislava_Region.html"
            className="btn-hotel"
          >
            Rezervujte teraz
          </a>
        </div>
      </li>
      <li className="hotel__item">
        <div className="hotel__img-container">
          <img
            src="/hotel-radisson.jpg"
            width={442}
            height={453}
            className="hotel__img"
          />
        </div>
        <div className="hotel__text-container">
          <h4 className="hotel__name">Radisson Blu Carlton Hotel</h4>
          <p className="t-16">
            Hviezdoslavovo Námestie 3, Bratislava 811 02, Slovensko
          </p>
          <p className="t-16">
            Radisson Blu Bratislava zaručuje výnimočný pobyt vďaka svojim 168
            izbám a apartmánom, ktoré dokonale spájajú tradičné pohodlie s
            modernými prvkami.
          </p>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g274924-d276309-Reviews-Radisson_Blu_Carlton_Hotel_Bratislava-Bratislava_Bratislava_Region.html"
            className="btn-hotel"
          >
            Rezervujte teraz
          </a>
        </div>
      </li>
    </ul>
  </section>
</>

    </Layout>
  );
};

export default Home;
