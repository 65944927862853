import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();
    if (location.pathname === "/" ) {
        return (
        <><div className="header__container"></div>
            <section className="cover">
              <img
                className="cover__img cover__pc"
                src="/hotel-bg.webp"
                alt="capa principal"
                loading="lazy"
                width={1440}
                height={700}
              />
              <img
                className="cover__img cover__mob"
                src="/hotel-bg.webp"
                alt="capa principal"
                width={360}
                height={1025}
                style={{ height: "100%", objectFit: "cover" }}
              />
              <div className="cover__overlay">
              <Link className="t-15" to="/">
                <img src="/logo.png" alt="" width={200} />
                </Link>
                <h1 className="cover__header col-w">
                  Zažite najlepšie hotelové kasína na Slovensku
                </h1>
                <p className="col-w t-16 cover__text">
                  Spojili sme najlepšie hotely na Slovensku na jednom mieste, aby sme vám
                  uľahčili nájsť dokonalú kombináciu luxusu, pohodlia a herného vzrušenia.
                </p>
              </div>
            </section>
            
            </>)
    }  else{
        return (
        <><div className="header__container"></div>
            <section className="cover">
              <img
                className="cover__img cover__pc"
                src="/hotel-bg.webp"
                alt="capa principal"
                loading="lazy"
                width={1440}
                height={700}
              />
              <img
                className="cover__img cover__mob"
                src="/hotel-bg.webp"
                alt="capa principal"
                width={360}
                height={1025}
                style={{ height: "100%", objectFit: "cover" }}
              />
              <div className="cover__overlay">
                
              <Link className="t-15" to="/page/">
                <img src="/logo.png" alt="" width={200} />
                </Link>
                <h1 className="cover__header col-w">
                  Zažite najlepšie hotelové kasína na Slovensku
                </h1>
                <p className="col-w t-16 cover__text">
                  Spojili sme najlepšie hotely na Slovensku na jednom mieste, aby sme vám
                  uľahčili nájsť dokonalú kombináciu luxusu, pohodlia a herného vzrušenia.
                </p>
              </div>
            </section>
            
            </>)
    }

};

export default Header;
