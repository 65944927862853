import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CookieBox = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, []);

  const extractGclidFromURL = () => {
    const currentURL = window.location.href;
    if (currentURL.indexOf('?') !== -1) {
      const queryString = currentURL.split('?')[1];
      const params = queryString.split('&');
      for (let param of params) {
        const [key, value] = param.split('=');
        if (key === 'gclid') {
          return value;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const finalGclid = extractGclidFromURL();
    const btnYes = document.getElementById("btnYes");
    const btnNo = document.getElementById("btnNo");

    if (btnYes) {
      btnYes.onclick = () => {
        navigate(finalGclid ? `/page?gclid=${finalGclid}` : "/page");
      };
    }

    if (btnNo) {
      btnNo.onclick = () => navigate("/privacy");
    }
  }, [navigate]);

  return (
    <div className="cookiebox">
      <div className="cookiebox-container">
        <div className="cookiebox-header">
          <h1>Používanie súborov cookie</h1>
        </div>
        <p>
          Na našej webovej stránke používame súbory cookie na zlepšenie vášho
          zážitku, analýzu návštevnosti a prispôsobenie obsahu. Prosím, potvrďte,
          či súhlasíte s ich používaním.
        </p>
        <div className="cookiebox-buttons">
          <button id="btnYes">Áno, súhlasím</button>
          <button id="btnNo">Nie, nesúhlasím</button>
        </div>
      </div>
    </div>
  );
};

export default CookieBox;
