import React from "react";
import Layout from "../components/Layout";

const Terms = () => {
    return (
    <Layout>
<>
<div className="hotel width">
            <style
    dangerouslySetInnerHTML={{
        __html:
        "\n       body {\n            font-family: Arial, sans-serif;\nx\n           margin: 40px;\n            line-height: 1.6;\n        }\n        .hotel {\n\n            padding-top: 100px\n        }\n    "
    }}
    />
  <h1>Podmienky používania</h1>
  <p>
    Vitajte na našej webovej stránke. Používaním tejto stránky súhlasíte s
    týmito podmienkami používania. Prosíme, aby ste si ich pozorne prečítali. Ak
    s týmito podmienkami nesúhlasíte, prosím, nepoužívajte našu stránku.
  </p>
  <h2>1. Prijatie podmienok</h2>
  <p>
    Používaním našej stránky vyjadrujete súhlas s týmito podmienkami.
    Vyhradzujeme si právo tieto podmienky kedykoľvek aktualizovať alebo zmeniť,
    a preto vám odporúčame pravidelne kontrolovať túto stránku, aby ste sa
    oboznámili s najnovšou verziou podmienok.
  </p>
  <h2>2. Prístup a používanie stránky</h2>
  <p>
    Máte povolenie používať našu stránku iba na osobné a nekomerčné účely. Je
    zakázané kopírovanie, distribúcia, úprava alebo verejné zobrazovanie obsahu
    bez nášho písomného súhlasu.
  </p>
  <h2>3. Používanie obsahu</h2>
  <p>
    Všetky texty, grafiky, logá, ikony tlačidiel, obrázky a iné materiály na
    našej stránke sú naším majetkom alebo majetkom našich poskytovateľov a sú
    chránené autorskými právami a inými zákonmi o duševnom vlastníctve.
  </p>
  <h2>4. Obmedzenie zodpovednosti</h2>
  <p>
    Nie sme zodpovední za žiadne priame, nepriame alebo následné škody, ktoré by
    mohli vzniknúť pri používaní tejto stránky. Používanie stránky je na vaše
    vlastné riziko.
  </p>
  <h2>5. Odkazy na iné webové stránky</h2>
  <p>
    Naša stránka môže obsahovať odkazy na iné stránky, ktoré nie sú pod našou
    kontrolou. Nezodpovedáme za obsah alebo ochranu osobných údajov na týchto
    stránkach a odporúčame vám prečítať si ich zásady ochrany osobných údajov a
    podmienky používania.
  </p>
  <h2>6. Zmeny podmienok</h2>
  <p>
    Vyhradzujeme si právo tieto podmienky kedykoľvek zmeniť bez predchádzajúceho
    upozornenia. Vaše ďalšie používanie stránky po vykonaní zmien znamená, že s
    týmito zmenami súhlasíte.
  </p>
  <h2>7. Kontaktujte nás</h2>
  <p>
    Ak máte akékoľvek otázky týkajúce sa týchto podmienok, neváhajte nás
    kontaktovať prostredníctvom <a href="contact.html">kontaktného formulára</a>
    .
  </p>
</div>
</>

</Layout>
  );
};

export default Terms;
