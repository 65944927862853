import React from "react";
import Layout from "../components/Layout";

const Privacy = () => {
    return (
    <Layout>
    <>
        <div className="hotel width">
            <style
    dangerouslySetInnerHTML={{
        __html:
        "\n       body {\n            font-family: Arial, sans-serif;\nx\n           margin: 40px;\n            line-height: 1.6;\n        }\n        .hotel {\n\n            padding-top: 100px\n        }\n    "
    }}
    />
    <h1>Zásady ochrany osobných údajov</h1>
    <p>
        Vaše súkromie je pre nás dôležité. Tieto zásady ochrany osobných údajov
        vysvetľujú, ako zhromažďujeme, používame a chránime vaše osobné údaje.
        Rešpektujeme vaše právo na súkromie a zaväzujeme sa chrániť vaše osobné
        údaje v súlade s platnými zákonmi a predpismi.
    </p>
    <h2>1. Aké údaje zhromažďujeme</h2>
    <p>Môžeme zhromažďovať nasledujúce typy údajov:</p>
    <ul>
        <li>
        <strong>Osobné identifikačné údaje:</strong> Meno, e-mailová adresa,
        telefónne číslo.
        </li>
        <li>
        <strong>Údaje o používaní:</strong> Informácie o vašom prehliadači, IP
        adresa, aktivita na našej stránke.
        </li>
        <li>
        <strong>Údaje o preferenciách:</strong> Vaše preferencie týkajúce sa
        ubytovania, obľúbené hotely a vyhľadávania.
        </li>
    </ul>
    <h2>2. Ako používame vaše údaje</h2>
    <p>Vaše osobné údaje používame na nasledujúce účely:</p>
    <ul>
        <li>Na poskytovanie a zlepšovanie našich služieb.</li>
        <li>Na komunikáciu s vami ohľadom vašich rezervácií alebo otázok.</li>
        <li>Na zasielanie informačných bulletinov, ak ste na to udelili súhlas.</li>
        <li>
        Na analýzu a pochopenie správania používateľov s cieľom zlepšiť našu
        stránku.
        </li>
    </ul>
    <h2>3. Zdieľanie údajov</h2>
    <p>
        Vaše údaje nezdieľame s tretími stranami, okrem prípadov, keď je to potrebné
        na poskytovanie služieb (napr. spracovanie platby) alebo ak je to požadované
        zákonom.
    </p>
    <h2>4. Uloženie údajov</h2>
    <p>
        Vaše údaje uchovávame len tak dlho, ako je potrebné na dosiahnutie účelu, na
        ktorý boli zhromaždené, alebo v súlade s právnymi požiadavkami.
    </p>
    <h2>5. Bezpečnosť údajov</h2>
    <p>
        Prijímame vhodné bezpečnostné opatrenia na ochranu vašich osobných údajov
        pred stratou, zneužitím alebo neoprávneným prístupom. Používame technológie
        ako šifrovanie a bezpečné servery.
    </p>
    <h2>6. Vaše práva</h2>
    <p>
        Máte právo na prístup, opravu alebo vymazanie vašich údajov. Môžete tiež
        namietať proti spracovaniu vašich údajov alebo požiadať o obmedzenie ich
        spracovania. Ak máte akékoľvek otázky alebo obavy, kontaktujte nás.
    </p>
    <h2>7. Zmeny zásad ochrany osobných údajov</h2>
    <p>
        Môžeme z času na čas aktualizovať tieto zásady. Akékoľvek zmeny budú
        zverejnené na tejto stránke s príslušným dátumom aktualizácie.
    </p>
    <h2>8. Kontaktujte nás</h2>
    <p>
        Ak máte akékoľvek otázky ohľadom ochrany osobných údajov, prosím,
        kontaktujte nás prostredníctvom{" "}
        <a href="contact">kontaktného formulára</a>.
    </p>
    </div>
    </>

</Layout>
  );
};

export default Privacy;
