import React, { useState } from "react";
import Layout from "../components/Layout";

const Contact = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (event) => {
      event.preventDefault(); 
      setIsSubmitted(true);   
      event.target.reset();   
    };

    
    return (
    <Layout>
<>
<div className="hotel width">
            <style
    dangerouslySetInnerHTML={{
        __html:
        "\n       body {\n            font-family: Arial, sans-serif;\nx\n           margin: 40px;\n            line-height: 1.6;\n        }\n        .hotel {\n\n            padding-top: 100px\n        }\n          form {margin-top: 20px;}input, textarea {width: 100%;padding: 10px;margin: 10px 0;border: 1px solid #ddd;border-radius: 4px;}button {background-color: #007BFF;color: white;padding: 10px 20px;border: none;border-radius: 4px;cursor: pointer;}  "
    }}
    />
  <h1>Kontakt</h1>
  <p>
    Máte otázky? Kontaktujte nás pomocou nasledujúceho formulára a radi vám
    odpovieme.
  </p>
  <form id="contactForm" onSubmit={handleSubmit}>
    <label htmlFor="name">Meno:</label>
    <input type="text" id="name" name="name" required="" />
    <label htmlFor="email">E-mail:</label>
    <input type="email" id="email" name="email" required="" />
    <label htmlFor="message">Správa:</label>
    <textarea
      id="message"
      name="message"
      rows={5}
      required=""
      defaultValue={""}
    />
    <button type="submit">Odoslať</button>
  </form>
  {isSubmitted && (
  <p
    id="thankYouMessage"
    style={{ display: "block", color: "green", fontWeight: "bold" }}
  >
    Ďakujeme za vašu správu! Čoskoro sa vám ozveme.
  </p>
   )}
</div>
</>

</Layout>
  );
};

export default Contact;
